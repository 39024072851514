export function interpolate(x, x0, y0, x1, y1) {
    if (x1 === x0) {
        return y0; // Prevent division by zero if x0 equals x1
    }
    return y0 + (x - x0) * (y1 - y0) / (x1 - x0);
}

export function getYeastPercentage(temp, hours, data) {
    let lowerTemp = null, upperTemp = null;
    let lowerTempValues = [], upperTempValues = [];

    // Convert JSON data into a more accessible format
    const dataMap = {};
    data.forEach(entry => {
        const key = `(${entry.Temperature}, ${entry["Fermentation hours"]})`;
        dataMap[key] = entry["Yeast %"];
    });

    // Check for an exact match first
    const exactKey = `(${temp}, ${hours})`;
    if (exactKey in dataMap) {
        return dataMap[exactKey] * 100; // Multiply by 100 for percentage
    }

    // Find the closest data points
    data.forEach(entry => {
        const t = entry.Temperature;

        if (t <= temp && (lowerTemp === null || t > lowerTemp)) lowerTemp = t;
        if (t >= temp && (upperTemp === null || t < upperTemp)) upperTemp = t;
    });

    // Gather values at lower and upper temperatures
    data.forEach(entry => {
        const t = entry.Temperature;
        const h = entry["Fermentation hours"];
        const value = entry["Yeast %"];

        if (t === lowerTemp) lowerTempValues.push([h, value]);
        if (t === upperTemp) upperTempValues.push([h, value]);
    });

    // Sort the values by hours
    lowerTempValues.sort((a, b) => a[0] - b[0]);
    upperTempValues.sort((a, b) => a[0] - b[0]);

    // Find the correct bounds for hours at lower temperature
    let lowerHoursLower = null;
    let lowerHoursUpper = null;
    for (let i = 0; i < lowerTempValues.length; i++) {
        if (lowerTempValues[i][0] <= hours) lowerHoursLower = lowerTempValues[i];
        if (lowerTempValues[i][0] >= hours && !lowerHoursUpper) lowerHoursUpper = lowerTempValues[i];
    }

    // Find the correct bounds for hours at upper temperature
    let upperHoursLower = null;
    let upperHoursUpper = null;
    for (let i = 0; i < upperTempValues.length; i++) {
        if (upperTempValues[i][0] <= hours) upperHoursLower = upperTempValues[i];
        if (upperTempValues[i][0] >= hours && !upperHoursUpper) upperHoursUpper = upperTempValues[i];
    }

    // Default to exact points if interpolation is not possible
    if (!lowerHoursLower) lowerHoursLower = lowerTempValues[0];
    if (!lowerHoursUpper) lowerHoursUpper = lowerTempValues[lowerTempValues.length - 1];
    if (!upperHoursLower) upperHoursLower = upperTempValues[0];
    if (!upperHoursUpper) upperHoursUpper = upperTempValues[upperTempValues.length - 1];

    // Interpolate at lower temperature
    let lowerTempYeast = interpolate(hours, lowerHoursLower[0], lowerHoursLower[1], lowerHoursUpper[0], lowerHoursUpper[1]);

    // Interpolate at upper temperature
    let upperTempYeast = interpolate(hours, upperHoursLower[0], upperHoursLower[1], upperHoursUpper[0], upperHoursUpper[1]);

    // Interpolate between the two temperatures
    return interpolate(temp, lowerTemp, lowerTempYeast, upperTemp, upperTempYeast) * 100; // Multiply by 100 for percentage
}

