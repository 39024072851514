<template>
  <div id="app" class="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
    <h1 class="text-3xl font-bold mb-6">Pizza Calculator</h1>
    
    <div class="space-y-4 w-full max-w-md">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <label for="numberOfBalls" class="text-base lg:text-lg">Pizza Balls</label>
          <input v-model.number="numberOfBalls" id="numberOfBalls" type="number" class="p-2 border rounded text-center font-bold w-full"/>
        </div>
        <div class="flex flex-col">
          <label for="ballWeight" class="text-base lg:text-lg">Ball Weight</label>
          <input v-model.number="ballWeight" id="ballWeight" type="number" class="p-2 border rounded text-center font-bold w-full"/>
        </div>
      </div>
      
      <div class="flex flex-col">
        <label for="waterPercentage" class="text-base lg:text-lg">Water</label>
        <div class="relative">
          <input v-model.number="waterPercentage" id="waterPercentage" type="number" step="1" class="p-2 border rounded text-center font-bold w-full"/>
          <span class="absolute right-3 top-2">%</span>
        </div>
      </div>
      
      <div class="flex justify-between">
        <div class="flex flex-col">
          <label for="temp" class="text-base lg:text-lg">Temperature</label>
          <input v-model.number="temp" id="temp" type="number" step="0.5" class="p-2 border rounded text-center font-bold w-full "/>
        </div>
        <div class="flex flex-col">
          <label for="hours" class="text-base lg:text-lg">Fermentation Hours</label>
          <input v-model.number="hours" id="hours" type="number" step="0.1" class="p-2 border rounded text-center font-bold w-full"/>
        </div>
      </div>

      <div class="flex justify-between">
        <div class="flex flex-col">
          <label for="oilPercentage" class="text-base lg:text-lg">Oil</label>
          <div class="relative">
            <input v-model.number="oilPercentage" id="oilPercentage" type="number" step="0.1" class="p-2 border rounded text-center font-bold w-full"/>
            <span class="absolute right-3 top-2">%</span>
          </div>
        </div>
        <div class="flex flex-col">
          <label for="saltPercentage" class="text-base lg:text-lg">Salt</label>
          <div class="relative">
            <input v-model.number="saltPercentage" id="saltPercentage" value="2" step="0.1" type="number" class="p-2 border rounded text-center font-bold w-full"/>
            <span class="absolute right-3 top-2">%</span>
          </div>
        </div>
      </div>

      <div class="flex justify-between">
        <div class="flex flex-col">
          <label for="sugarPercentage" class="text-base lg:text-lg">Sugar</label>
          <div class="relative">
            <input v-model.number="sugarPercentage" id="sugarPercentage" type="number" step="0.1" class="p-2 border rounded text-center font-bold w-full"/>
            <span class="absolute right-3 top-2">%</span>
          </div>
        </div>
        <div class="flex flex-col">
          <label for="maltPercentage" class="text-base lg:text-lg">Malt</label>
          <div class="relative">
            <input v-model.number="maltPercentage" id="maltPercentage" type="number" step="0.1" class="p-2 border rounded text-center font-bold w-full"/>
            <span class="absolute right-3 top-2">%</span>
          </div>
        </div>
      </div>
      
      <button @click="calculateIngredients" class="w-full bg-blue-500 text-white p-2 rounded mt-4">Calculate Ingredients</button>
    </div>

    <div v-if="ingredientResults" class="mt-6 w-full max-w-md bg-white p-4 rounded shadow">
      <h2 class="text-2xl font-semibold mb-4">Ingredient Results</h2>
      <div class="flex justify-between"><span>Flour:</span> <span>{{ ingredientResults.flour.toFixed(2) }}g</span></div>
      <div class="flex justify-between"><span>Water:</span> <span>{{ ingredientResults.water.toFixed(2) }}g</span></div>
      <div class="flex justify-between"><span>Oil:</span> <span>{{ ingredientResults.oil.toFixed(2) }}g</span></div>
      <div class="flex justify-between"><span>Salt:</span> <span>{{ ingredientResults.salt.toFixed(2) }}g</span></div>
      <div class="flex justify-between"><span>Sugar:</span> <span>{{ ingredientResults.sugar.toFixed(2) }}g</span></div>
      <div class="flex justify-between"><span>Malt:</span> <span>{{ ingredientResults.malt.toFixed(2) }}g</span></div>
      <div class="flex justify-between">
        <span>Yeast:</span> 
        <span>{{ (ingredientResults.flour * yeastPercentage / 100).toFixed(3) }}g ({{ yeastPercentage.toFixed(3) }}%)</span>
      </div>
    </div>

    <div class="mt-6 w-full max-w-md">
      <div class="flex flex-col">
        <label for="recipeName" class="text-base lg:text-lg">Recipe Name:</label>
        <input v-model="recipeName" id="recipeName" type="text" class="p-2 border rounded text-center font-bold"/>
      </div>
      <button @click="saveRecipe" class="w-full bg-green-500 text-white p-2 rounded mt-4">Save Recipe</button>
      <button @click="loadRecipes" class="w-full bg-gray-500 text-white p-2 rounded mt-2">Load Recipes</button>
    </div>

    <ul class="mt-6 w-full max-w-md">
      <li v-for="recipe in recipes" :key="recipe._id" class="bg-white p-4 rounded shadow mb-2 flex justify-between items-center">
        {{ recipe.name }}
        <div>
          <button @click="deleteRecipe(recipe.name)" class="bg-red-500 text-white p-2 rounded mr-2">Delete</button>
          <button @click="loadRecipe(recipe.recipe)" class="bg-blue-500 text-white p-2 rounded">Load</button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import  yeastData  from './data.json';
import { getYeastPercentage } from './utils';

export default {
  data() {
    return {
      temp: 18,
      hours: 6,
      yeastPercentage: null,
      numberOfBalls: 1,
      ballWeight: 300,
      waterPercentage: 60,
      oilPercentage: 1,
      saltPercentage: 2,
      sugarPercentage: 1,
      maltPercentage: 1,
      ingredientResults: null,
      recipeName: '',
      recipes: [],
    };
  },
  methods: {
    calculateIngredients() {
      console.log("Calculating yeast percentage and ingredients...");  // Debugging log
      // Calculate yeast percentage
      if (this.temp !== null && this.hours !== null) {
        this.yeastPercentage = getYeastPercentage(this.temp, this.hours, yeastData);
      } else {
        this.yeastPercentage = null;
      }

      // Calculate ingredients
      const totalDoughWeight = this.numberOfBalls * this.ballWeight;
      const flourWeight = totalDoughWeight / (1 + this.waterPercentage / 100 + this.oilPercentage / 100 + this.saltPercentage / 100 + this.sugarPercentage / 100 + this.maltPercentage / 100);

      this.ingredientResults = {
        flour: flourWeight,
        water: flourWeight * (this.waterPercentage / 100),
        oil: flourWeight * (this.oilPercentage / 100),
        salt: flourWeight * (this.saltPercentage / 100),
        sugar: flourWeight * (this.sugarPercentage / 100),
        malt: flourWeight * (this.maltPercentage / 100),
      };
    },
    async saveRecipe() {
      if (!this.recipeName) {
        alert("Please enter a name for your recipe.");
        return;
      }

      if (this.temp === null || this.hours === null || this.ingredientResults === null) {
        alert("Please fill out all required fields and calculate ingredients before saving.");
        return;
      }

      const recipe = {
        temp: this.temp,
        hours: this.hours,
        yeastPercentage: this.yeastPercentage,
        numberOfBalls: this.numberOfBalls,
        ballWeight: this.ballWeight,
        waterPercentage: this.waterPercentage,
        oilPercentage: this.oilPercentage,
        saltPercentage: this.saltPercentage,
        sugarPercentage: this.sugarPercentage,
        maltPercentage: this.maltPercentage,
        ingredientResults: this.ingredientResults,
      };

      try {
        const response = await fetch(`https://pizza-calculator-five.vercel.app/api/save`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: this.recipeName, recipe }),
        });

        if (!response.ok) {
          throw new Error('Failed to save the recipe.');
        }

        const data = await response.json();
        alert(data.message);
      } catch (error) {
        console.error('Error saving recipe:', error);
        alert('Error saving recipe. Please try again.');
      }
    },
    async loadRecipes() {
      try {
        console.log('Loading recipes...'); // Debugging log
        const response = await fetch(`https://pizza-calculator-five.vercel.app/api/load`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to load recipes.');
        }

        const data = await response.json();
        console.log('Loaded recipes:', data.data); // Debugging log
        this.recipes = data.data; // Assuming the API response contains an array of recipes under 'data'
      } catch (error) {
        console.error('Error loading recipes:', error);
        alert('Error loading recipes. Please try again.');
      }
    },
    async deleteRecipe(name) {
      if (!name) {
        alert("Please provide a name for the recipe to delete.");
        return;
      }
      try {
        const response = await fetch(`https://pizza-calculator-five.vercel.app/api/delete?name=${encodeURIComponent(name)}`, {
          method: 'DELETE',
        });
        if (!response.ok) {
          throw new Error('Failed to delete the recipe.');
        }
        const message = await response.json();
        alert(message.message);
        // Reload recipes after deletion
        this.loadRecipes();
      } catch (error) {
        console.error('Error deleting recipe:', error);
        alert(error.message);
      }
    },
    loadRecipe(recipe) {
      console.log('Loading recipe:', recipe); // Debugging log
      this.temp = recipe.temp;
      this.hours = recipe.hours;
      this.yeastPercentage = recipe.yeastPercentage;
      this.numberOfBalls = recipe.numberOfBalls;
      this.ballWeight = recipe.ballWeight;
      this.waterPercentage = recipe.waterPercentage;
      this.oilPercentage = recipe.oilPercentage;
      this.saltPercentage = recipe.saltPercentage;
      this.sugarPercentage = recipe.sugarPercentage;
      this.maltPercentage = recipe.maltPercentage;
      this.ingredientResults = recipe.ingredientResults;

   
    },
  },
};
</script>

<style scoped>
  @import "tailwindcss/tailwind.css";
  .relative input {
    padding-right: 2rem; /* Adjust this value as needed to ensure there's enough space for the percentage symbol */
  }
  .relative span {
    pointer-events: none;
  }
</style>